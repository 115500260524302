import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  MenuItem
} from '@mui/material';
import {
  getTemplateById,
  getDocumentById,
  reuploadDocument,
  updateDocument,
  getAllTemplates
} from '../../../constants/requests';
import { style } from '../common/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotifications } from '../../../hooks/useNotifications';
import TabPanel from '@mui/lab/TabPanel';
import SetTable from '../common/SetTable';
import TabContext from '@mui/lab/TabContext';
import TemplateTable from '../common/TemplateTable';
import SingleLineForm from '../common/SingleLineForm';
import VersionManager from '../common/VersionManager';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ModalNotFound from '../../Services/common/ModalNotFound';

const DocumentCreator = () => {
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const { request } = useLoading();
  const { documentId } = useParams();
  const { showNotifications } = useNotifications();
  const [deleteCall, setDeleteCall] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [tableName, setTableName] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [docManagerId, setDocManagerId] = useState(null);
  const [templateGroup, setTemplateGroup] = useState(null);
  const [currentTabName, setCurrentTabName] = useState(null);
  const [dataFetchingStrategy, setDataFetchingStrategy] = useState(null);
  const [configObj, setConfigObj] = useState({});
  const [rulesList, setRulesList] = useState({});
  const [tableColumns, setTableColumns] = useState({});
  const [dataSourceObj, setDataSourceObj] = useState({});
  const [sheetName, setSheetName] = useState([]);
  const [tableView, setTableView] = useState([]);
  const [fieldNameOptions, setFieldNameOptions] = useState([]);

  const rulesForm = {
    field1: 'fieldName',
    field2: 'operator',
    field3: 'value'
  };

  const operatorOptions = [
    {
      label: 'Equals',
      value: 'equals'
    }
  ];

  const fieldTypeOptions = [
    {
      label: 'Field',
      value: 'field'
    },
    {
      label: 'String',
      value: 'string'
    }
  ];

  const isUniqueOptions = [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ];

  const dataSourceOptions = [
    {
      label: 'RCM',
      value: 'RCM'
    },
    {
      label: 'POF',
      value: 'POF'
    },
    {
      label: 'TRUST_SERVICE_CRITERIA',
      value: 'TRUST_SERVICE_CRITERIA'
    },
    {
      label: 'CUEC',
      value: 'CUEC'
    },
    {
      label: 'CSOC',
      value: 'CSOC'
    }
  ];

  const metaData = {
    fieldName: {
      isEditable: false,
      fieldType: 'multiLineTextField',
      displayName: 'Field Name'
    },
    type: {
      isEditable: true,
      fieldType: 'dropDown',
      options: fieldTypeOptions,
      displayName: 'Type'
    },
    value: {
      isEditable: true,
      fieldType: 'dropDown',
      options: fieldNameOptions,
      displayName: 'Value'
    },
    isUnique: {
      isEditable: true,
      fieldType: 'dropDown',
      options: isUniqueOptions,
      displayName: 'Is Unique?'
    }
  };

  const changeTypeObj = {
    check: 'type',
    equals: 'string',
    change: 'value',
    to: 'multiLineTextField'
  };

  const downloadStrategy = [
    {
      label: 'Download Single File For Each Application',
      value: 'SINGLE_APPLICATION'
    },
    {
      label: 'Download Combined File For All Applications',
      value: 'ALL_APPLICATIONS'
    }
  ];

  const handleCloseTableModal = () => {
    setTableName(null);
    setTableView([]);
    setOpenTableModal(false);
  };

  const handleDataSourceChange = (e) => {
    const newData = { ...configObj };
    newData['tables'][currentTabName]['dataSource'] = e.target.value;
    setFieldNameOptions([
      ...dataSourceObj['default'],
      ...dataSourceObj[e.target.value]
    ]);
    setConfigObj(newData);
  };

  const handleColumnChange = (rowNumber, field, cellValue) => {
    const newData = { ...tableColumns };

    newData[currentTabName][rowNumber][field] = cellValue;
    setTableColumns(newData);
    const { fieldName, type, value, isUnique } =
      newData[currentTabName][rowNumber];
    const newConfigObj = Object.assign(configObj);
    newConfigObj['tables'][currentTabName]['columns'][fieldName]['type'] = type;
    newConfigObj['tables'][currentTabName]['columns'][fieldName]['isUnique'] =
      isUnique;
    newConfigObj['tables'][currentTabName]['columns'][fieldName]['value'] =
      value;
    setConfigObj(newConfigObj);
  };

  const handleRulesUpdate = (changeRuleList) => {
    const newRulesObj = Object.assign(rulesList);
    const newConfigObj = Object.assign(configObj);
    for (const ruleName in changeRuleList) {
      const { fieldName, operator, value } = changeRuleList[ruleName];
      if (fieldName === '') continue;
      if (newRulesObj[currentTabName] === undefined) {
        newRulesObj[currentTabName] = {};
      }
      newRulesObj[currentTabName][fieldName] = {};
      newRulesObj[currentTabName][fieldName]['operator'] = operator;
      newRulesObj[currentTabName][fieldName]['value'] = value;
    }
    newConfigObj['tables'][currentTabName]['filters'] =
      newRulesObj[currentTabName];
    setConfigObj(newConfigObj);
    setRulesList(newRulesObj);
  };

  const handleDeleteRules = async (idx) => {
    const oldRules = Object.assign(rulesList[currentTabName]);
    const removeName = tableView[idx]['fieldName'];
    const newConfigObj = Object.assign(configObj);
    const newRules = Object.fromEntries(
      Object.entries(oldRules).filter(([key]) => key !== removeName)
    );
    setRulesList((rulesList) => ({ ...rulesList, [currentTabName]: newRules }));
    const newTableView = tableView.filter(
      (ele) => ele['fieldName'] !== removeName
    );
    setTableView(newTableView);
    newConfigObj['tables'][currentTabName]['filters'] = newRules;

    if (Object.keys(newRules).length === 0) {
      delete newConfigObj['tables'][currentTabName]['filters'];
    }

    setConfigObj(newConfigObj);
    setDeleteCall((deleteCall) => !deleteCall);
  };

  const setDataSourceFields = () => {
    const dataSource = configObj['tables'][currentTabName]['dataSource'];

    if (dataSource === undefined || dataSource === null || dataSource === '') {
      setFieldNameOptions([
        ...dataSourceObj['default'],
        ...dataSourceObj['RCM']
      ]);
    } else {
      setFieldNameOptions([
        ...dataSourceObj['default'],
        ...dataSourceObj[dataSource]
      ]);
    }
  };

  const handleTabChange = (e) => {
    setCurrentTabName(e.target.value);
    setDataSourceFields();
  };

  const getDataSourceFields = async (initialSourceObj) => {
    try {
      const res = await request(() => getAllTemplates());
      if (res.data.statusCode === 200) {
        const pofTemplate = res.data.body.filter(
          (templates) => templates.templateGroup === 'POINT_OF_FOCUS'
        )[0];
        const tscTemplate = res.data.body.filter(
          (templates) => templates.templateGroup === 'TRUST_CRITERIA'
        )[0];
        const cuecTemplate = res.data.body.filter(
          (templates) => templates.templateGroup === 'CUEC_CSOC'
        )[0];
        const pofFields = Object.keys(pofTemplate.data.meta.fields).map(
          (key) => {
            return {
              value: key,
              label: key
            };
          }
        );
        const tscFields = Object.keys(tscTemplate.data.meta.fields).map(
          (key) => {
            return {
              value: key,
              label: key
            };
          }
        );
        const cuecFields = Object.keys(cuecTemplate.data.meta.fields).map(
          (key) => {
            return {
              value: key,
              label: key
            };
          }
        );
        const additionalDataSource = {
          POF: pofFields,
          CUEC: cuecFields,
          CSOC: cuecFields,
          TRUST_SERVICE_CRITERIA: tscFields
        };
        setDataSourceObj({ ...initialSourceObj, ...additionalDataSource });
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadTemplate = async (templateId) => {
    try {
      const res = await request(() => getTemplateById(templateId));
      if (res.data.statusCode === 200) {
        setTemplateName(res.data.body.templateName);
        setTemplateGroup(res.data.body.templateGroup);
        const fieldOptions = Object.keys(res.data.body.data.meta.fields).map(
          (key) => {
            return {
              value: key,
              label: key
            };
          }
        );
        const defaultFields = [
          {
            label: 'SNo(Consolidated)',
            value: 'SNo(Consolidated)'
          },
          {
            label: 'Application Name',
            value: 'Application Name'
          },
          {
            label: 'Application Description',
            value: 'Application Description'
          }
        ];
        const initialSourceObj = {
          default: defaultFields,
          RCM: fieldOptions
        };
        await getDataSourceFields(initialSourceObj);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleView = async (currentTableName) => {
    setOpenTableModal(true);
    setTableName(currentTableName);
    try {
      const res = await request(() => getDocumentById(documentId));
      if (res.data.statusCode === 200) {
        const rulesData = res.data.body.config.tables[currentTabName];
        if (rulesData['filters'] !== undefined) {
          const result = Object.keys(rulesData['filters']).map((ruleName) => {
            return {
              fieldName: ruleName,
              ...rulesData['filters'][ruleName],
              Action: 'Delete'
            };
          });
          setTableView(result);
        } else {
          setTableView([]);
        }
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadDocument = async () => {
    try {
      const res = await request(() => getDocumentById(documentId));
      if (res.data.statusCode === 200) {
        await loadTemplate(res.data.body.templateId);
        setDocumentName(res.data.body.documentName);
        setDataFetchingStrategy(res.data.body.dataFetchingStrategy);
        setDocManagerId(res.data.body.documentManagerId);
        const temp = res.data.body.config;
        if (temp.tables === undefined) return;
        const nameOfSheets = Object.keys(temp.tables);
        setConfigObj(temp);
        setSheetName(nameOfSheets);
        setCurrentTabName(nameOfSheets[0]);
        let table = {},
          rulesObj = {};
        for (const sheetName of nameOfSheets) {
          const cols = Object.keys(temp.tables[sheetName]['columns']).map(
            (key) => {
              return {
                fieldName: key,
                type: temp.tables[sheetName]['columns'][key]['type'],
                value: temp.tables[sheetName]['columns'][key]['value'],
                isUnique: temp.tables[sheetName]['columns'][key]['isUnique']
              };
            }
          );
          if (temp.tables[sheetName]['filters'] !== undefined) {
            rulesObj[sheetName] = temp.tables[sheetName]['filters'];
          }

          table[sheetName] = cols;
        }
        setRulesList(rulesObj);
        setTableColumns(table);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleUpdateDocument = async () => {
    try {
      const payload = {
        documentName,
        dataFetchingStrategy,
        config: configObj
      };
      const res = await request(() => updateDocument(documentId, payload));
      if (res.data.statusCode === 200) {
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    loadDocument();
    return () => {
      setTemplateName(null);
      setTemplateGroup(null);
      setRulesList({});
      setTableView([]);
      setFieldNameOptions([]);
    };
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleUpdateDocument();
    }
  }, [deleteCall]);

  useEffect(() => {
    if (dataSourceObj !== {} && configObj !== {} && currentTabName !== null) {
      setDataSourceFields();
    }
  }, [dataSourceObj, configObj, currentTabName]);

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{ color: 'white', backgroundColor: 'black' }}
          variant="contained"
          onClick={() => navigate(`/templateEditor`)}
        >
          <ChevronLeftIcon />
          Template Home
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Add Documents
        </Typography>
        <Button
          variant="contained"
          color="success"
          onClick={handleUpdateDocument}
        >
          Save
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between'
        }}
      >
        <TextField
          fullWidth
          name="documentName"
          value={documentName}
          label="Enter Document Name"
          onChange={(e) => setDocumentName(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          select
          value={dataFetchingStrategy}
          name="dataFetchingStrategy"
          label="Select Download Option"
          onChange={(e) => setDataFetchingStrategy(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {downloadStrategy.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between'
        }}
      >
        <TextField
          fullWidth
          disabled
          value={templateName}
          name="templateName"
          label="Enter Template Name"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          disabled
          value={templateGroup}
          name="templateGroup"
          label="Select Template Group"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <VersionManager
        heading="Documents"
        formDataText="document"
        entityId={documentId}
        uploadRequest={reuploadDocument}
        refreshCB={loadDocument}
        docManagerId={docManagerId}
      />

      {configObj && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginBottom: '15px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="h4">
            Config
          </Typography>

          <Box
            style={{
              display: 'flex',
              gap: '100px',
              marginBottom: '15px'
            }}
          >
            <Typography variant="h5">Table</Typography>
            <TextField
              select
              label="Tab Name"
              value={currentTabName}
              onChange={handleTabChange}
              style={{ minWidth: '200px' }}
              size="small"
              InputLabelProps={{ shrink: true }}
            >
              {sheetName.length > 0 &&
                sheetName?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          </Box>

          <TabContext value={currentTabName}>
            {tableColumns
              ? Object.keys(tableColumns).map((sheetName) => {
                  return (
                    <TabPanel
                      key={`Document-Table${sheetName}`}
                      value={sheetName}
                      index={sheetName}
                      sx={{ p: '0px' }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          marginBottom: '15px'
                        }}
                      >
                        <TextField
                          select
                          label="Data Source"
                          value={
                            configObj['tables'][currentTabName]['dataSource']
                          }
                          onChange={handleDataSourceChange}
                          style={{ minWidth: '200px' }}
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        >
                          {dataSourceOptions.length > 0 &&
                            dataSourceOptions?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>

                        <SingleLineForm
                          formData={rulesForm}
                          heading="filters"
                          fieldOptions={fieldNameOptions}
                          typeOptions={operatorOptions}
                          saveFields={handleRulesUpdate}
                          viewCB={handleView}
                        />

                        <SetTable
                          tableMeta={metaData}
                          data={tableColumns[sheetName]}
                          saveFields={handleColumnChange}
                          changeType={changeTypeObj}
                          styles={style}
                        />
                      </Box>
                    </TabPanel>
                  );
                })
              : null}
          </TabContext>
        </Box>
      )}

      <Modal
        open={openTableModal}
        onClose={handleCloseTableModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '80%', height: '80%', overflowX: 'scroll' }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px'
            }}
          >
            <Typography
              fullWidth
              id="modal-modal-title"
              variant="h5"
              component="h2"
            >
              {tableName}
            </Typography>
            <Button
              color="error"
              variant="contained"
              onClick={handleCloseTableModal}
            >
              Close
            </Button>
          </Box>
          {tableView.length > 0 ? (
            <TemplateTable data={tableView} deleteCB={handleDeleteRules} />
          ) : (
            <ModalNotFound data="Records For This Document" />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DocumentCreator;
